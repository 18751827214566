import React from 'react';

import {ApiHostsEnum} from '../../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../../entities/pageSetup/apiHosts/useApiHost';
import {translate} from '../../../../services';
import InfoBox from '../../../Reusable/InfoBox/InfoBox';
import LinkWrapper from '../../../Reusable/LinkWrapper/LinkWrapper';


export interface TradeInfoBoxProps {
  isDesktop: boolean;
}

const TradeInfoBox = ({isDesktop}: TradeInfoBoxProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);

  return (
    <InfoBox
      isDesktop={isDesktop}
      boxClass='b-trade-info-box x-icon-box'
      heading={translate('fabric.trade.title')}
      infoText={translate('fabric.trade.info')}
      imageUrl={`${imagesHost}/trade-swatches-icon.svg`}
      imageAlt={translate('fabric.trade.title')}
      moreInfo={<LinkWrapper
        className='learn-more-link'
        hrefValue={translate('navigation.aboutUsTradeProgram.href')}
        title={translate('fabric.trade.title')}
        contents={translate('fabric.trade.learnMore')}
        ariaLabel={`${translate('fabric.trade.learnMore')} - ${translate('fabric.trade.title')}`}
      />}
    />
  );
};

export default TradeInfoBox;
