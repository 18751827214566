import classNames from 'classnames';
import isUndefined from 'lodash/isUndefined';
import React, {useEffect, useState} from 'react';
import AnimateOnChange from 'react-animate-on-change';
import './_item-price.scss';

import {PRICE_VALID_UNTIL} from '../../../constants/DateFormats';
import {translate} from '../../../services';
import {defaultBasePrice} from '../../../utils/fabrics';
import {getUnitPricePer} from '../../../utils/formattingHelpers';
import {isNotUndefined} from '../../../utils/validation';


export interface ItemPriceProps {
  isCart?: boolean;
  rawPrice?: number;
  rawPriceUndiscounted?: number;
  extensionClass: string;
  pricePerFoot?: string;
  pricePerYard?: string;
  pricePerMeter?: string;
  pricePerUnit?: string;
  unitType?: string;
  discountsApplied?: boolean;
  itemPriceClasses?: string;
  currency: string;
  showPricePer?: boolean;
  addition?: string;
  offeredBy?: string;
  rawItemPriceAddition?: number;
  rawItemPriceAdditionUndiscounted?: number;
}
const ItemPrice = ({
  rawPrice, rawPriceUndiscounted, rawItemPriceAddition, rawItemPriceAdditionUndiscounted, pricePerFoot,
  pricePerUnit, pricePerMeter, addition, pricePerYard, unitType, discountsApplied, itemPriceClasses,
  currency, showPricePer, offeredBy, isCart, extensionClass
}: ItemPriceProps): JSX.Element => {
  const [animatePrice, setAnimatePrice] = useState(false);
  // AnimateOnChange does not have a working TS Type, best solution found is the partial typing below
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const AnimateOnChangeEffect = AnimateOnChange as React.ClassType<string, any, any>;

  useEffect(() => {
    if (isNotUndefined(rawPrice)) {
      setAnimatePrice(true);
    }
  }, [rawPrice]);

  // TODO EN-1673: Wallpaper pricePerUnit
  const getUndiscountedPricePer = () => (
    pricePerUnit ? pricePerUnit :
      (pricePerYard ? pricePerYard :
        (pricePerMeter ? pricePerMeter : pricePerFoot)
      )
  );

  const renderPricePerUnitString = (hasPricePer: boolean) => {
    if (hasPricePer && showPricePer && pricePerUnit && pricePerYard && pricePerMeter && unitType) {
      const pricePerUnitString = translate('measurements.priceInfo', {
        price: getUndiscountedPricePer(),
        unit: translate(`measurements.${getUnitPricePer(pricePerUnit, pricePerYard, pricePerMeter, unitType)}`)
      });

      return <span className='item-price-per'>{pricePerUnitString}</span>;
    }
  };

  const renderDefaultItemPrice = (priceClasses: string, discountedPriceClasses: string, hasPricePer: boolean, totalPrice: number, totalPriceUndiscounted: number) => (
    <React.Fragment>
      {currency &&
        <div className='visuallyhidden'>
          <span itemProp='price'>{rawPrice}</span>
          <span itemProp='priceCurrency'>{currency}</span>
          {offeredBy && <span itemProp='offeredBy'>{offeredBy}</span>}
          <time itemProp='priceValidUntil' dateTime={PRICE_VALID_UNTIL}>{PRICE_VALID_UNTIL}</time>
        </div>}
      {discountsApplied &&
      <AnimateOnChangeEffect
        baseClassName={discountedPriceClasses}
        animationClassName='x-animate'
        animate={animatePrice}>
        {defaultBasePrice(totalPrice, currency)}
      </AnimateOnChangeEffect>}
      {isNotUndefined(totalPriceUndiscounted) &&
        <AnimateOnChangeEffect
          baseClassName={priceClasses}
          animationClassName='x-animate'
          animate={animatePrice}>
          <link itemProp='availability' href='http://schema.org/InStock'/>
          {defaultBasePrice(totalPriceUndiscounted, currency)}
        </AnimateOnChangeEffect>}
      {renderPricePerUnitString(hasPricePer)}
    </React.Fragment>
  );

  const renderCartItemPrice = (priceClasses: string, discountedPriceClasses: string, hasPricePer: boolean, totalPrice: number, totalPriceUndiscounted: number) => (
    <React.Fragment>
      {currency &&
        <div className='visuallyhidden'>
          <span itemProp='price'>{rawPrice}</span>
          <span itemProp='priceCurrency'>{currency}</span>
          {offeredBy && <span itemProp='offeredBy'>{offeredBy}</span>}
          <time itemProp='priceValidUntil' dateTime={PRICE_VALID_UNTIL}>{PRICE_VALID_UNTIL}</time>
        </div>}
      {renderPricePerUnitString(hasPricePer)}
      {discountsApplied &&
        <AnimateOnChangeEffect
          baseClassName={discountedPriceClasses}
          animationClassName='x-animate'
          animate={animatePrice}>
          <span className='visuallyhidden'>New price: </span>
          {defaultBasePrice(totalPrice, currency)}
        </AnimateOnChangeEffect>}
      {isNotUndefined(totalPriceUndiscounted) &&
        <AnimateOnChangeEffect
          baseClassName={priceClasses}
          animationClassName='x-animate'
          animate={animatePrice}>
          <link itemProp='availability' href='http://schema.org/InStock'/>
          <span className='visuallyhidden'>Old price: </span>
          {defaultBasePrice(totalPriceUndiscounted, currency)}
        </AnimateOnChangeEffect>}
    </React.Fragment>
  );

  const discountedPriceClasses = classNames('item-price-discounted', itemPriceClasses);
  const blockClassName = classNames('b-item-price', extensionClass);
  const priceClasses = classNames('item-price', itemPriceClasses, {
    'x-discounted': discountsApplied
  });

  if (isUndefined(rawPrice) || isUndefined(rawPriceUndiscounted)) { // this is to handle the fact that the actual price may not be provided,
    return <div className={blockClassName} itemProp='offers' itemScope itemType='http://schema.org/Offer'/>;
  }

  const hasPricePer = Boolean(pricePerFoot || pricePerUnit || pricePerYard || pricePerMeter);
  const totalPrice = addition && rawItemPriceAddition ? rawItemPriceAddition : rawPrice;
  const totalPriceUndiscounted = addition && rawItemPriceAdditionUndiscounted ? rawItemPriceAdditionUndiscounted : rawPriceUndiscounted;

  return (
    <div className={blockClassName} itemProp='offers' itemScope itemType='http://schema.org/Offer'>
      {isCart ?
        renderCartItemPrice(
          priceClasses, discountedPriceClasses, hasPricePer, totalPrice, totalPriceUndiscounted
        ) :
        renderDefaultItemPrice(
          priceClasses, discountedPriceClasses, hasPricePer, totalPrice, totalPriceUndiscounted
        )}
    </div>
  );
};

export default React.memo(ItemPrice);
