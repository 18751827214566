import React from 'react';

import './_product-info-fabric-details.scss';
import {translate} from '../../../services';
import {translateOrEmptyString} from '../../../utils/url';
import {isNotUndefined} from '../../../utils/validation';
import {renderSpecList} from '../../../utils/ymlFormatting';
import LazyImage from '../../Reusable/LazyImage/LazyImage';


export interface ProductInfoFabricDetailsProps {
  abrasionMartindale: string;
  abrasionWyzenbeekDuck: string;
  abrasionWyzenbeekWire: string;
  breakingStrength: string;
  certification: string;
  certificationImage: string;
  certificationImageExists: boolean;
  color: string;
  colorfastness: string;
  colorFastnessAcid: string;
  colorFastnessAlkaline: string;
  colorFastnessPoolWater: string;
  colorFastnessSeaWater: string;
  dryCrocking: string;
  fabricConstruction: string;
  fabricContent: string;
  fabricPilling: string;
  fabricPrice?: string;
  fabricWidth: string;
  features: string;
  flammability: string;
  handleShopClick?: (pathName?: string) => void;
  needleSize: string;
  needleType: string;
  pileHeight: string;
  printableWidth?: string;
  shrinkage: string;
  snagStrength: string;
  stitchesInch: string;
  stretchDetail: string;
  tearStrength: string;
  threadCount: string;
  threadSize: string;
  threadType?: string;
  upfRating: string;
  uvaUvb: string;
  weight: string;
  wetCrocking: string;
  showOnlyProductInfo?: boolean;
}

const ProductInfoFabricDetails = ({
  fabricPrice, handleShopClick, printableWidth, weight, fabricContent, fabricConstruction, color, shrinkage,
  abrasionWyzenbeekDuck, abrasionWyzenbeekWire, abrasionMartindale, certification, certificationImage, needleType,
  needleSize, threadSize, stitchesInch, threadType, stretchDetail, fabricPilling, dryCrocking, wetCrocking, threadCount,
  colorfastness, flammability, breakingStrength, tearStrength, snagStrength, pileHeight, certificationImageExists, uvaUvb, upfRating, colorFastnessAcid,
  colorFastnessAlkaline, colorFastnessSeaWater, colorFastnessPoolWater, features, fabricWidth, showOnlyProductInfo
}: ProductInfoFabricDetailsProps): JSX.Element => {
  const navigateToShopPage = () => handleShopClick && handleShopClick();
  const handleFaqButtonClick = () => window.location.href = translateOrEmptyString('xurls.solids.faq');
  const navigateToFabricPage = () => handleShopClick && handleShopClick('shop.fabric');

  return (
    <div className='b-product-info-fabric-details'>
      <section className='fabric-specs'>
        {renderSpecList(translate('fabric.fabricDetail.specifications'), {
          printableWidth, fabricWidth, weight, fabricContent, fabricConstruction, color, shrinkage, abrasionWyzenbeekDuck, abrasionWyzenbeekWire,
          abrasionMartindale, stretchDetail, fabricPilling, dryCrocking, wetCrocking, colorfastness, flammability, breakingStrength,
          tearStrength, snagStrength, threadCount, pileHeight, uvaUvb, upfRating, colorFastnessAcid, colorFastnessAlkaline,
          colorFastnessSeaWater, colorFastnessPoolWater, features
        })}
        {renderSpecList(translate('fabric.fabricDetail.sewingRecommendations'), {
          needleType, needleSize, threadSize, stitchesInch, threadType
        })}
      </section>
      {!certification.includes('.certification') && <section className='fabric-certificates'>
        <div className='certificates-title'>{translate('fabric.fabricDetail.certifications')}</div>
        <div className='certificate-box'>
          {certificationImage && certificationImageExists &&
          <LazyImage
            className='certificate'
            src={certificationImage}
            alt={certification}
            width='50'
            height='50'/>}
          <em className='certificate-text'>{certification}</em>
        </div>
      </section>}
      <section className='fabric-cta'>
        {fabricPrice &&
        <div className='fabric-price'>{fabricPrice}</div>}
        {(showOnlyProductInfo && isNotUndefined(handleShopClick)) &&
          <button
            className='btn white-button x-dark btn-faq x-non-full-width-mobile-button'
            type='button'
            onClick={handleFaqButtonClick}>
            {translate('solidFabrics.content.faq')}
          </button>
        }
        {(isNotUndefined(handleShopClick) && !showOnlyProductInfo) &&
        <React.Fragment>
          <button
            className='btn white-button x-dark btn-shop-designs x-non-full-width-mobile-button'
            type='button'
            onClick={navigateToShopPage}>
            {translate('fabric.fabricDetail.shopDesigns')}
          </button>
          <button
            className='btn white-button x-dark btn-select-different x-non-full-width-mobile-button'
            type='button'
            onClick={navigateToFabricPage}>
            {translate('fabric.fabricDetail.selectDifferentFabric')}
          </button>
        </React.Fragment>
        }
      </section>
    </div>
  );
};

export default ProductInfoFabricDetails;
