import classNames from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';

import {FABRIC, FABRIC_BLANK, WALLPAPER} from '../../../constants/Codes';
import SwiperWrapperContainer from '../../../containers/Design/SwiperWrapperContainer';
import {ApiHostsEnum} from '../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../entities/pageSetup/apiHosts/useApiHost';
import {translate} from '../../../services';
import {CrossSellDesign, CrossSellCollection, SolidCrossSellItem, isCrossSellSolid} from '../../../shapes/crossSell';
import {Locale} from '../../../shapes/locale';
import {ProductTypeAbbreviated} from '../../../shapes/products';
import {ViewportType} from '../../../shapes/viewport';
import {State} from '../../../store/initialState';
import ImageUrls from '../../../utils/design';
import {trimmedLowerString} from '../../../utils/string';
import {isFunction, isNotUndefined} from '../../../utils/validation';
import ColorItemPdp from '../../Reusable/ColorItem/ColorItemPdp';
import Loading from '../../Reusable/Loading/Loading';
import DesignItemFabric from '../DesignItem/DesignItemFabric';
import DesignItemPdp from '../DesignItem/DesignItemPdp';
import './_design-cross-sell-list.scss';
import DesignItemPDPSeeMore from '../DesignItem/DesignItemPDPSeeMore';


export const maxSlidesToShowOnCrossSellDesignsSlider = 8;

export interface DesignCrossSellListProps {
  productTypeAbbreviated: ProductTypeAbbreviated;
  locale: Locale;
  crossSellDesigns: CrossSellDesign[] | CrossSellCollection[] | SolidCrossSellItem[];
  viewport: ViewportType;
  productType: string;
  orderItemId?: string; // used in params
  selectedSubstrate: string;
  sizeQuery?: string;
  getPosition?: (element: HTMLElement | null) => void;
  pushProductClickToGTM?: (designId: number, designerName: string, index: number, target_path: string, userId?: number) => void;
  userId?: number;
  headerTitle?: string;
  pricePerUnitText?: string;
  children?: JSX.Element[];
  isOnFabricTypePDP?: boolean;
}

const DesignCrossSellList = ({
  orderItemId, selectedSubstrate, viewport, crossSellDesigns, productType, sizeQuery, getPosition, productTypeAbbreviated,
  locale, pushProductClickToGTM, userId, headerTitle: customHeaderTitle, pricePerUnitText, children, isOnFabricTypePDP,
}: DesignCrossSellListProps): JSX.Element => {
  const gardenHost = useApiHost(ApiHostsEnum.garden);
  const design = useSelector((state: State) => state.design);

  const swiperParams = {
    slidesPerView: 'auto',
    breakpoints: {
      430: {
        slidesPerGroup: 2
      },
      690: {
        slidesPerGroup: 3
      }
    }
  };
  const headerTitle = customHeaderTitle || (isNotUndefined(productType) && translate(`designs.crossSellHeader.${productType}`));

  const fabricQuery = selectedSubstrate && selectedSubstrate.includes(WALLPAPER) ? selectedSubstrate.toLowerCase() :
    trimmedLowerString(selectedSubstrate, 7, selectedSubstrate.length);

  const crossSellListClassName = classNames('b-design-cross-sell-list', {
    'x-no-border': productTypeAbbreviated === FABRIC_BLANK,
  });

  let swiperItems: JSX.Element[] = [];

  if (crossSellDesigns.length > 0) {
    if (isCrossSellSolid(crossSellDesigns)) {
      swiperItems = crossSellDesigns.map((option, index) => (
        <div key={index}>
          <ColorItemPdp
            colorId={option.id}
            colorTitle={translate(`solidColors.${option.color_code}.name`)}
            imageUrl={option.thumbnail_path}
            pushProductClickToGTM={pushProductClickToGTM}
            index={index}
            fabricQuery={option.fabric}
            colorQuery={option.color}
            orderItemId={orderItemId}
            userId={userId}
          />
        </div>
      ));
    } else if (!isOnFabricTypePDP) {
      swiperItems = crossSellDesigns.slice(0, maxSlidesToShowOnCrossSellDesignsSlider).map((design, index) => {
        const {id, name, designer, slug, collection_slug, collection_name, design_thumbnail} = design as CrossSellDesign;

        return (
          <div key={index}>
            <DesignItemPdp
              locale={locale}
              productTypeAbbreviated={productTypeAbbreviated}
              designId={id}
              orderItemId={orderItemId}
              designTitle={name}
              designerName={designer.screen_name}
              slug={slug}
              collectionSlug={collection_slug}
              collectionName={collection_name}
              imageUrl={(productType === FABRIC) ?
                ImageUrls.cipheredDesignThumbnail(gardenHost, id, design_thumbnail) :
                'thumbnail_url' in design ? design.thumbnail_url : ''}
              pushProductClickToGTM={pushProductClickToGTM}
              index={index}
              fabricQuery={fabricQuery}
              productType={productType}
              sizeQuery={sizeQuery}
              userId={userId}
            />
          </div>);
      });
      design && swiperItems.push(
        <div key={swiperItems.length}>
          <DesignItemPDPSeeMore
            designId={design.id ?? 0}
            designTitle={design.name ?? ''}
            imageUrl={(productType === FABRIC) ?
              ImageUrls.cipheredDesignThumbnail(gardenHost, design.id, design.design_thumbnail) :
              'thumbnail_url' in design ? design.thumbnail_url : ''}
            productType={productType}
          />
        </div>
      );
    } else {
      swiperItems = crossSellDesigns.map((design, index) => {
        const {collection_slug, collection_name, search_params, thumbnail_url} = design as CrossSellCollection;

        return (
          <div key={index}>
            <DesignItemFabric
              collectionSlug={collection_slug}
              collectionName={collection_name}
              searchParams={search_params}
              imageUrl={thumbnail_url}
            />
          </div>
        );
      });
    }
  }
  if (children) {
    swiperItems.push(...children);
  }

  // https://github.com/kidjp85/react-id-swiper/issues/116
  return (
    <section className={crossSellListClassName} ref={isFunction(getPosition) ? ((element) => getPosition(element)) : null}>
      <h2 className='h3'>{headerTitle}</h2>
      {pricePerUnitText &&
        <div className='unit-price'>
          {pricePerUnitText}
        </div>
      }
      {crossSellDesigns.length === 0 ?
        <Loading message={translate('common.loading')} /> :
        <SwiperWrapperContainer
          viewport={viewport}
          swiperParams={swiperParams}
          navigationClasses='x-on-top-of-slides'
        >
          {swiperItems}
        </SwiperWrapperContainer>}
    </section>
  );
};

export default DesignCrossSellList;
