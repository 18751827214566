import Translate from 'i18n-react';
import React, {SyntheticEvent} from 'react';

import {HOME_GOOD, WALLPAPER} from '../../../constants/Codes';
import {collectionIdFromSlug, collectionUrlCreator} from '../../../constants/Collections';
import {
  FABRIC_PARAM, ORDER_ITEM_ID, SIZE
} from '../../../constants/Parameters';
import {ROUTING_PATH_FABRIC, ROUTING_PATH_WALLPAPER} from '../../../constants/Products';
import {EN} from '../../../constants/SupportedLanguages';
import {ApiHostsEnum} from '../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../entities/pageSetup/apiHosts/useApiHost';
import useOrigin from '../../../entities/pageSetup/origin/useOrigin';
import {translate, MultiLocaleRouter} from '../../../services';
import {Locale} from '../../../shapes/locale';
import {ProductTypeAbbreviated} from '../../../shapes/products';
import {setPlaceholderImage} from '../../../utils/design';
import {upsertUrlQuery} from '../../../utils/url';
import {isUndefined} from '../../../utils/validation';
import Icon from '../../Reusable/Icon/Icon';
import LazyImage from '../../Reusable/LazyImage/LazyImage';
import LinkWrapper from '../../Reusable/LinkWrapper/LinkWrapper';
import './_design-item.scss';


export interface DesignItemPdpProps {
  productTypeAbbreviated: ProductTypeAbbreviated;
  productType: string;
  locale: Locale;
  designId: number;
  designerName: string;
  orderItemId?: string; // used in params
  designTitle: string;
  slug: string;
  imageUrl: string;
  collectionSlug?: string;
  collectionName?: string;
  pushProductClickToGTM?: (designId: number, designerName: string, index: number, productLink: string, userId?: number) => void;
  index: number;
  fabricQuery: string;
  sizeQuery?: string;
  userId?: number;
}

const DesignItemPdp = ({
  designerName, imageUrl, designTitle, collectionSlug, collectionName, designId, locale,
  slug, orderItemId, productType, productTypeAbbreviated, pushProductClickToGTM, index, fabricQuery,
  sizeQuery, userId
}: DesignItemPdpProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);
  const currentOrigin = useOrigin();

  let multiLocalePathname = '';

  switch (productTypeAbbreviated) {
    case WALLPAPER:
      multiLocalePathname = ROUTING_PATH_WALLPAPER;
      break;
    case HOME_GOOD:
      multiLocalePathname = productType;
      break;
    default:
      multiLocalePathname = ROUTING_PATH_FABRIC;
  }
  const productLinkParam = {
    [ORDER_ITEM_ID]: orderItemId,
    [SIZE]: sizeQuery,
    [FABRIC_PARAM]: fabricQuery
  };
  const productLink = upsertUrlQuery(`${MultiLocaleRouter.localePathname(multiLocalePathname)}/${slug || designId}`, productLinkParam, currentOrigin);
  const titleText = translate('tags.main.chooseDesign');

  const renderSubtextContent = () => (
    isUndefined(collectionSlug) ? (
      <p className='item-author-wrapper'>
        {translate('tags.main.authorBy')}
        <LinkWrapper
          className='item-author'
          itemProp='brand'
          hrefValue={Translate.translate('xurls.userProfile', { // TODO: SP-8362 Refactor or extend translate utility.
            userName: designerName
          })?.toString() || ''}
          title={translate('tags.main.authorLinkTitle', {
            designerName
          })}
          contents={designerName ? designerName : ''}/>
      </p>
    ) : (
      <LinkWrapper
        className='item-in-collection-link'
        hrefValue={collectionUrlCreator(collectionIdFromSlug(collectionSlug), true)}
        title={collectionName ? translate('collections.links.collectionTilesTitle',{
          collectionName
        }) : translate('collections.userCollections.linkIconTitle')}
        target='_blank'
        contents={
          <React.Fragment>
            <Icon iconName='collection-in'/>
            <span>{collectionName ? collectionName : translate('collections.links.designAlsoIn')}</span>
          </React.Fragment>}
      />
    )
  );

  const onErrorFunc = (e: SyntheticEvent<HTMLImageElement, Event>) => setPlaceholderImage(e, imagesHost);
  const pushProductOnClick = () => pushProductClickToGTM && pushProductClickToGTM(designId, designerName, index, productLink, userId);
  const localeIsEnglish = locale === EN;
  const image = <LazyImage
    aspectRatio={true}
    className='item-pic'
    src={imageUrl}
    alt={designTitle}
    itemProp='image'
    onError={onErrorFunc}
    height='294'
    width='294'
    data-testid='design-item-image'/>;

  return (
    <div
      className='b-design-item x-condensed'
      itemProp='isRelatedTo'
      itemScope itemType='https://schema.org/Product'
      onClick={pushProductOnClick}>
      <LinkWrapper hrefValue={productLink} contents={image} title={`${titleText} by ${designerName}`} className='design-link' />
      <div className='design-item-text'>
        {(!localeIsEnglish && designTitle) && <span className='visuallyhidden' itemProp='name'>{designTitle}</span>}
        {designId && <span className='visuallyhidden' itemProp='productID'>{designId}</span>}
        {renderSubtextContent()}
      </div>
    </div>
  );
};

export default DesignItemPdp;
