import classNames from 'classnames';
import React, {SyntheticEvent} from 'react';

import './_search-box.scss';
import {translate} from '../../../services';
import {isFunction} from '../../../utils/validation';
import Icon from '../Icon/Icon';


export interface SearchBoxProps {
  inputPlaceholder: string;
  inputRefCallback?: (input: HTMLInputElement) => void;
  inputText?: string;
  onChangeInputText: (inputText: string) => void;
  extensionClass?: string;
  updateInputFocused?: (focused: boolean) => void;
  autoComplete?: () => JSX.Element;
  onInputKeyUp?: (() => void) | ((event: React.KeyboardEvent<HTMLInputElement>) => void);
  onButtonClick: () => void;
  showAutoFill?: boolean;
  updateShowAutoFill?: (event: SyntheticEvent) => void;
  clearSearch?: () => void;
  searchWrapperFullWidth?: boolean;
  searchBoxId?: string;
}

const SearchBox = ({
  updateInputFocused, inputPlaceholder, inputText, onInputKeyUp, inputRefCallback, onButtonClick, showAutoFill,
  updateShowAutoFill, clearSearch, searchWrapperFullWidth, extensionClass, autoComplete,
  searchBoxId, onChangeInputText
}: SearchBoxProps): JSX.Element => {
  const searchWrapperClasses = classNames('b-search-box', extensionClass, {
    'x-search-wrapper-full-width': searchWrapperFullWidth
  });

  const handleShowAutoFill = updateShowAutoFill ? (event: SyntheticEvent) => updateShowAutoFill(event) : undefined;
  const handleOnMouseEnter = updateInputFocused ? () => updateInputFocused(true) : undefined;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChangeInputText(event.target.value);

  return (
    <div className={searchWrapperClasses} onMouseLeave={handleShowAutoFill}>
      <section className='search-text-input-wrapper' role='search' aria-label={translate('search.querySitewide')}>
        <input
          aria-autocomplete='list'
          autoComplete='off'
          className='search-input'
          value={inputText}
          onChange={handleChange}
          id={searchBoxId}
          onMouseEnter={handleOnMouseEnter}
          onFocus={handleShowAutoFill}
          onKeyUp={onInputKeyUp}
          placeholder={inputPlaceholder}
          ref={inputRefCallback}
          role='searchbox'
          data-testid='search-input'
        />
        {showAutoFill && autoComplete?.()}
        {isFunction(clearSearch) && inputText &&
          <button
            type='reset'
            onClick={clearSearch}
            className='btn white-button x-dark x-square clear-search-button'
            aria-label={translate('fabricShop.clearSearch')}
            title={translate('fabricShop.clearSearch')}>
            <Icon iconName='close' iconTitle={translate('fabricShop.clearSearch')} />
          </button>}
        <button
          id='submit-search'
          type='submit'
          onClick={onButtonClick}
          className='btn x-dark x-square next-to-input search-box-button'
          aria-label={translate('search.searchButtonTitle')}
          title={translate('search.searchButtonTitle')}
          onBlur={handleShowAutoFill}>
          <Icon iconName='search' />
        </button>
      </section>
    </div>
  );
};

export default SearchBox;
