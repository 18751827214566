import classNames from 'classnames';
import React, {useState, useRef} from 'react';
import './_afterpay-info-box.scss';

import {ApiHostsEnum} from '../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../entities/pageSetup/apiHosts/useApiHost';
import {translate} from '../../../services';
import {PdpAfterpayTrue, CheckoutAfterpayTrue, isPdpTrue} from '../../../shapes/afterpayAvailability';
import {afterpayInstallmentInfo, getAfterpayLogo} from '../../../utils/afterpayInstallmentInfo';
import AfterpayModal from '../AfterpayModal/AfterpayModal';
import Icon from '../Icon/Icon';


interface AfterpayInfoBoxProps {
  afterpayAvailability: PdpAfterpayTrue | CheckoutAfterpayTrue;
  country: string;
  isTextButton: boolean;
}

const AfterpayInfoBox = ({afterpayAvailability, country, isTextButton}: AfterpayInfoBoxProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);
  const {eligible, installments, config: {minValue, maxValue, currency}} = afterpayAvailability;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModalBtn = useRef<HTMLButtonElement | null>(null);

  const afterpayInfoBoxClasses = classNames('b-afterpay-info-box', {
    'x-text-button': isTextButton
  });
  const afterpayButtonClasses = classNames('x-info-box', {
    'link-button': isTextButton,
    'btn white-button space-right space-bottom x-100-percent-width': !isTextButton
  });

  const showInstallments = isPdpTrue(afterpayAvailability) ?
    afterpayAvailability.installments.showInstallments :
    afterpayAvailability.priceValid;

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
    modalIsOpen && openModalBtn.current?.focus();
  };
  const isPayment = false;
  const installmentInfo = afterpayInstallmentInfo(imagesHost, showInstallments, installments.summary, currency, minValue, maxValue, isPayment);

  return (
    <section className={afterpayInfoBoxClasses}>
      <button
        data-target='#afterpay-modal'
        ref={openModalBtn}
        type='button'
        className={afterpayButtonClasses}
        aria-label={eligible ?
          `${translate('afterpay.modal.openDetailModal')} ${installmentInfo}` :
          translate('afterpay.modal.openDetailModal')}
        onClick={toggleModal}>
        {eligible && <span>{installmentInfo}
          <Icon iconName='help' extensionClass='x-icon-help2'/>
        </span>}
        {!eligible && translate('afterpay.messaging.notEligible', {
          afterpay: getAfterpayLogo(imagesHost)
        })}
      </button>
      {modalIsOpen && <AfterpayModal toggleModal={toggleModal} country={country}/>}
    </section>
  );
};

export default React.memo(AfterpayInfoBox);
