/* eslint complexity: "off" */
/* eslint max-lines: "off" */
import classNames from 'classnames';
import Translate from 'i18n-react';
import Cookies from 'js-cookie';
import React, {MutableRefObject, useEffect, useState} from 'react';
import {browserHistory} from 'react-router';
import {reduxForm} from 'redux-form';
import {ResetAction} from 'redux-form/lib/reduxForm';

import {
  FABRIC_PETAL_SIGNATURE_COTTON, FABRIC_POLARTEC_FLEECE, TEST_SWATCH_METER,
  TEST_SWATCH_YARD, FAT_QUARTER_METER, FAT_QUARTER_YARD, WALLPAPER, HOME_GOOD, FABRIC_BLANK, SOLID,
  NOT_FSC_WALLPAPERS
} from '../../../constants/Codes';
import {SCROLL_POSITION_MOBILE_ADD_TO_CART} from '../../../constants/Design';
import {IMPERIAL, YARD, METER} from '../../../constants/Measurements';
import {FABRIC_PARAM, ORDER_ITEM_ID, SIZE} from '../../../constants/Parameters';
import {
  PRODUCT_STYLE, PRODUCT_SIZE, PRODUCT_ADDITION, hasNoSizeOption,
  pillowWithoutFlangedOption, IS_WALLPAPER, IS_FABRIC, WALLPAPER_IMPERIAL_TEST_SWATCH_2_x_1, WALLPAPER_METRIC_TEST_SWATCH_60_96_x_30, ROUTING_PATH_FABRIC, isColorMapProduct
} from '../../../constants/Products';
import {isTabletDown} from '../../../constants/Viewports';
import {ApiHostsEnum} from '../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../entities/pageSetup/apiHosts/useApiHost';
import useOrigin from '../../../entities/pageSetup/origin/useOrigin';
import {MultiLocaleRouter, translate} from '../../../services';
import {PdpAfterpayTrue} from '../../../shapes/afterpayAvailability';
import {Colorway} from '../../../shapes/colorways';
import {Locale} from '../../../shapes/locale';
import {MeasurementType} from '../../../shapes/measurement';
import {isProductWithAdditions, ProductPricingMaps} from '../../../shapes/pricing';
import {ProductTypeAbbreviated} from '../../../shapes/products';
import {RefScrollIntoView} from '../../../shapes/refScroll';
import {ImagesData} from '../../../shapes/solids';
import {InternationalTag} from '../../../shapes/tags';
import {hasTurnaroundDays, TurnaroundTime} from '../../../shapes/turnaroundTime';
import {UserType} from '../../../shapes/user';
import {ViewportType} from '../../../shapes/viewport';
import {defaultBasePrice} from '../../../utils/fabrics';
import {shopHomeUrl, upsertUrlQuery} from '../../../utils/url';
import {isNotUndefined, isUndefined, validateProductForm, objectIsNotEmpty, isFunction, isEmpty} from '../../../utils/validation';
import AfterpayInfoBox from '../../Reusable/AfterpayInfoBox/AfterpayInfoBox';
import TextBox from '../../Reusable/Content/TextBox/TextBox';
import {ErrorBoundary} from '../../Reusable/ErrorBoundary/ErrorBoundary';
import AddToCartButton from '../../Reusable/FormElements/AddToCartButton/AddToCartButton';
import QuantityChanger from '../../Reusable/FormElements/QuantityChanger/QuantityChanger';
import Icon from '../../Reusable/Icon/Icon';
import LazyImage from '../../Reusable/LazyImage/LazyImage';
import LinkWrapper from '../../Reusable/LinkWrapper/LinkWrapper';
import PromoInfo from '../../Reusable/PromoInfo/PromoInfo';

import DesignHeading from './DesignHeading/DesignHeading';
import ProductFormCarousel from './ProductFormCarousel/ProductFormCarousel';
import ProductFormColorways from './ProductFormColorways/ProductFormColorways';
import ProductFormFabricDetails from './ProductFormFabricDetails/ProductFormFabricDetails';
import ProductFormOptions from './ProductFormOptions/ProductFormOptions';
import ProductFormPrice from './ProductFormPrice/ProductFormPrice';
import RecommendedFabric from './RecommendedFabric/RecommendedFabric';
import SelectColor from './SelectColor/SelectColor';
import SelectFabric from './SelectFabric/SelectFabric';
import SelectWallpaper from './SelectWallpaper/SelectWallpaper';

import './_product-form.scss';


export interface ProductFormProps {
  selectedSubstrate: string;
  measurementSystem: MeasurementType;
  currency: string;
  locale: Locale;
  country: string;
  productType: string;
  productAvailable: boolean;
  quantity: number;
  viewport: ViewportType;
  // optional / not from all parents
  extensionFormClass?: string;
  sizeQueryParam?: string;
  productSize?: string;
  outOfStock?: boolean;
  taxRate?: number;
  fabricSizeSizingMap?: Record<string, unknown>;
  productPricingMap?: ProductPricingMaps;
  orderItemId?: number;
  rawItemPrice?: number;
  rawItemPriceUndiscounted?: number;
  discountsApplied?: boolean;
  designId?: number;
  productTypeAbbreviated?: ProductTypeAbbreviated;
  designName?: string;
  designerUserName?: string;
  designThumbnail?: string;
  addToCartIsFetching?: boolean;
  isFetchingFabrics?: boolean;
  orderItemIsFetching?: boolean;
  userId?: number;
  userType?: UserType;
  userIsDesigner?: boolean;
  reviewSummary?: Record<string, number>[];
  buttonContentIsAdded?: boolean;
  buttonContent?: string | Record<string, unknown>;
  recommendedFabricCode?: string;
  recommendedFabricWidth?: string;
  fabricList?: string[];
  testSwatchPrice?: number;
  pricePerMeter?: number;
  pricePerYard?: number;
  pricePer?: number;
  tags?: InternationalTag[];
  designImages?: Record<string, unknown>;
  userIsPrivateBuyer?: boolean;
  isAvailableOnProduct?: boolean;
  homeGoodType?: string;
  wallpaperType?: string;
  productFormTitle?: string;
  addToCartButtonTitle?: string;
  selectedAddition?: string;
  hasAdditionPossible?: boolean;
  additionPricing?: Record<string, Record<string, unknown>>;
  rawItemPriceAddition?: number;
  rawItemPriceAdditionUndiscounted?: number;
  sizesAreAvailable?: boolean;
  stylesAreAvailable?: boolean;
  sizeStyleMap?: Record<string, Record<string, unknown>>;
  carouselImages?: Record<string, unknown>;
  solidImages?: ImagesData;
  solidColorCode?: string;
  solidHexCode?: string;
  solidSize?: string;
  productInfoRef?: MutableRefObject<HTMLButtonElement & RefScrollIntoView | null>;
  fabricPriceSwatch?: number;
  hasSquareImages?: boolean;
  swiperParams?: Record<string, unknown>;
  singleFabricSizeAvailable?: boolean;
  afterpayAvailability?: PdpAfterpayTrue;
  showAfterpayWidget?: boolean;
  addToCartClick?: (itemProps: Record<string, string | number | undefined>) => void;
  addSwatchToCartClick?: (swatchProps: Record<string, string | number>) => void;
  setQuantity?: (quantity: number) => void;
  setProductSize?: (productSize: string) => void;
  setAddition?: (addition: string) => void;
  handleShopClick?: (pathName?: string) => void;
  handleSwatchClick?: (size: string) => void;
  openFabricPreferencesModal?: () => void;
  openWallpaperCalculatorModal?: () => void;
  openSelectColorModal?: (event: {preventDefault: () => void}) => void;
  completingQuantityInputUpdate: (isComplete: boolean) => void;
  // eslint-disable-next-line react/no-unused-prop-types
  reset?: ResetAction;
  quantityInputUpdateComplete: boolean;
  turnaroundTime?: TurnaroundTime;
  promoName?: string;
  colorways?: Colorway[];
  selectedColorway?: Colorway;
  routingPath?: string;
  queryParams?: Record<string, string>;
  designSlug?: string;
  stylesArray?: string[];
  setWallpaper: (newWallpaper: string) => void;
  modalInitiatorRef?: React.RefObject<HTMLButtonElement>;
}

const ProductForm = (props: ProductFormProps): JSX.Element => {
  const {
    locale, measurementSystem, currency, designId, designName, designerUserName, tags, selectedSubstrate,
    outOfStock, fabricList, recommendedFabricCode, fabricSizeSizingMap, productPricingMap, productFormTitle,
    isAvailableOnProduct, homeGoodType, wallpaperType, selectedAddition, orderItemId, quantity, productSize,
    setProductSize, taxRate, rawItemPrice, rawItemPriceUndiscounted, rawItemPriceAddition, rawItemPriceAdditionUndiscounted,
    discountsApplied, testSwatchPrice, pricePerMeter, pricePerYard, userId, userType, productType, userIsDesigner,
    userIsPrivateBuyer, reviewSummary, buttonContent, buttonContentIsAdded, addToCartButtonTitle, addToCartClick,
    addSwatchToCartClick, addToCartIsFetching, orderItemIsFetching, isFetchingFabrics, openFabricPreferencesModal,
    hasAdditionPossible, recommendedFabricWidth, sizesAreAvailable, stylesAreAvailable, productTypeAbbreviated,
    productAvailable, designThumbnail, productInfoRef, handleShopClick, handleSwatchClick, fabricPriceSwatch,
    hasSquareImages, singleFabricSizeAvailable, afterpayAvailability, showAfterpayWidget, country, setQuantity,
    setAddition, sizeStyleMap, additionPricing, designImages, carouselImages, swiperParams, sizeQueryParam, solidImages,
    solidColorCode, solidHexCode, solidSize, pricePer, extensionFormClass, openSelectColorModal, openWallpaperCalculatorModal,
    completingQuantityInputUpdate, quantityInputUpdateComplete, turnaroundTime, promoName, colorways, selectedColorway,
    routingPath, queryParams, designSlug, viewport, stylesArray, setWallpaper, modalInitiatorRef
  } = props;
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const isHomeGoodOrWallpaper = isNotUndefined(homeGoodType) || productType === WALLPAPER;

    (isHomeGoodOrWallpaper && objectIsNotEmpty(productPricingMap) && isFunction(setProductSize) && sizeQueryParam &&
      Object.keys(productPricingMap).includes(sizeQueryParam)) && setProductSize(sizeQueryParam);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const measurementCookie = Cookies.get('substrateMeasurementCookie');

    if (isNotUndefined(measurementCookie) && isFunction(setProductSize) && isUndefined(homeGoodType)) {
      setProductSize(measurementCookie);
      document.cookie = 'substrateMeasurementCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC;Secure;Path=/';
    } else {
      (isFunction(setProductSize) && isNotUndefined(productSize) && (objectIsNotEmpty(fabricSizeSizingMap) || objectIsNotEmpty(productPricingMap))) &&
        setProductSize(productSize);
    }
  }, [productSize]);

  const currentOrigin = useOrigin();

  const pushSizeToUrl = (size: string) => {
    const routerPath = isNotUndefined(routingPath) ? routingPath : ROUTING_PATH_FABRIC;

    if (isNotUndefined(designId) || isNotUndefined(designSlug)) {
      browserHistory.replace(
        upsertUrlQuery(
          `${MultiLocaleRouter.localePathname(routerPath)}/${designId || designSlug}`,
          {
            [FABRIC_PARAM]: queryParams?.[FABRIC_PARAM],
            [ORDER_ITEM_ID]: queryParams?.[ORDER_ITEM_ID],
            [SIZE]: size,
          },
          currentOrigin
        )
      );
    } else {
      browserHistory.replace(shopHomeUrl(locale));
    }
  };

  const handleOnChange = (inputQuantity: number, inputProductSize?: string, fieldName?: string) => {
    if (inputQuantity && isFunction(setQuantity) && (quantity !== inputQuantity)) {
      setQuantity(inputQuantity);
    }

    if (isFunction(setProductSize) && (isNotUndefined(inputProductSize) && productSize !== inputProductSize) && isUndefined(homeGoodType)) {
      pushSizeToUrl(inputProductSize);
      setProductSize(inputProductSize);
    }

    if (isNotUndefined(homeGoodType) && objectIsNotEmpty(sizeStyleMap) && typeof productSize === 'string') {
      const isSize = fieldName === PRODUCT_SIZE;
      let parameter = '';
      let sizeSet = false;
      let styleSet = false;
      const relatedSize = Object.keys(sizeStyleMap[productSize])[0];
      const relatedStyle = isNotUndefined(inputProductSize) && sizeStyleMap[productSize][relatedSize];

      Object.keys(sizeStyleMap).forEach((sizeStyle) => {
        const size = Object.keys(sizeStyleMap[sizeStyle])[0];
        const style = Object.values(sizeStyleMap[sizeStyle])[0];

        if (isSize && size === inputProductSize) {
          if (style === relatedStyle) {
            parameter = sizeStyle;
            sizeSet = true;
          } else if (style !== relatedStyle && !sizeSet) {
            parameter = sizeStyle;
          }
        } else if (!isSize && style === inputProductSize) {
          if (size === relatedSize) {
            parameter = sizeStyle;
            styleSet = true;
          } else if (style !== relatedSize && !styleSet) {
            parameter = sizeStyle;
          }
        }
      });

      if (productSize !== parameter && isFunction(setProductSize) && isNotUndefined(inputProductSize)) {
        setProductSize(parameter);
        pushSizeToUrl(parameter);
        (hasAdditionPossible && isFunction(setAddition)) && setAddition('');
      }
    }
  };

  const handleScroll = () => (
    setScrollPosition(window.pageYOffset)
  );

  const handleAddSwatch = () => (
    isFunction(addSwatchToCartClick) && addSwatchToCartClick({
      productSize: swatchSize,
      quantity: 1
    }));

  const handleAddToCart = () => (
    isFunction(addToCartClick) && addToCartClick({
      productSize, quantity
    }));

  const getAdditions = (): ProductPricingMaps | undefined => (
    (hasAdditionPossible && objectIsNotEmpty(productPricingMap) && typeof productSize === 'string' && isProductWithAdditions(productPricingMap, productSize)) ?
      productPricingMap[productSize]['home_goods_additions'] : undefined
  );

  const preventDefaultOnSubmit = (e: React.FormEvent<HTMLFormElement>) => e.preventDefault();

  const isWallpaper = IS_WALLPAPER(productTypeAbbreviated);
  const isFabric = IS_FABRIC(productTypeAbbreviated);
  const isOutOfStock = outOfStock || false;
  const isWallpaperOrFabric = isFabric || isWallpaper;
  const isEditMode = false;
  const dataIsFetching = addToCartIsFetching || isFetchingFabrics || orderItemIsFetching;
  const designNotPurchasable = !userIsDesigner && !userIsPrivateBuyer && !isAvailableOnProduct && productType !== FABRIC_BLANK && productType !== SOLID;
  const buttonIsDisabled = dataIsFetching || outOfStock || designNotPurchasable || buttonContentIsAdded || !productAvailable || !quantityInputUpdateComplete;
  const buttonAddToCartIsVisible = scrollPosition > SCROLL_POSITION_MOBILE_ADD_TO_CART;
  const taxApplied = typeof taxRate === 'number' && taxRate > 0;
  const recommendedFabricIsActive = (isNotUndefined(fabricList) && recommendedFabricCode) && fabricList.includes(recommendedFabricCode);
  const recommendedFabricIsPetal = recommendedFabricCode === FABRIC_PETAL_SIGNATURE_COTTON;
  const measurementSystemIsImperial = measurementSystem === IMPERIAL;
  const pricePerUnit = pricePer ? pricePer : (measurementSystemIsImperial ? pricePerYard : pricePerMeter);
  const swatchSize = isWallpaper ? measurementSystemIsImperial ? WALLPAPER_IMPERIAL_TEST_SWATCH_2_x_1 : WALLPAPER_METRIC_TEST_SWATCH_60_96_x_30 :
    measurementSystemIsImperial ? TEST_SWATCH_YARD : TEST_SWATCH_METER;
  const fabricUnit = measurementSystemIsImperial ? YARD : METER;
  const isFabricFqSize = productSize === FAT_QUARTER_YARD || productSize === FAT_QUARTER_METER;
  const isFabricSwatchSize = productSize === TEST_SWATCH_YARD || productSize === TEST_SWATCH_METER;
  const isFscWallpaperType = isWallpaper && !NOT_FSC_WALLPAPERS.includes(selectedSubstrate);

  const isHomeGood = productTypeAbbreviated === HOME_GOOD;
  const displayHomeGoodSizes = isHomeGood && sizesAreAvailable;
  const displayHomeGoodStyles = isHomeGood && stylesAreAvailable;
  const showTurnaroundTime = isNotUndefined(turnaroundTime);
  const imagesHost = useApiHost(ApiHostsEnum.images);
  const renderSwatchButtonText = () => {
    const price = testSwatchPrice ?? fabricPriceSwatch;
    const size = translate('common.fabricSize.TEST_SWATCH_YARD');
    const substrate = translate(`fabricShop.${selectedSubstrate}.name`);
    const text = isNotUndefined(price) ?
      translate(
        'fabric.content.buyATestSwatch',
        {
          context: defaultBasePrice(price, currency),
        },
      ) :
      undefined;
    const title = `1${size} ${substrate} ${designName}`;

    return {
      text,
      title,
    };
  };

  const renderTurnaroundTime = () => {
    // Logic for responding to different delivery_options (RUSH, STANDARD, GUARANTEE) to go here
    if (isUndefined(turnaroundTime)) {
      return; // Compiler doesn't remember the check for undefined in showTurnaround
    }

    const turnaroundRange = hasTurnaroundDays(turnaroundTime.STANDARD) ? translate('checkout.delivery.turnaroundDays', {
      turnaround_days: turnaroundTime.STANDARD.days
    }) : translate('checkout.delivery.turnaroundRange', {
      turnaround_min: turnaroundTime.STANDARD.min_days,
      turnaround_max: turnaroundTime.STANDARD.max_days
    });

    return (
      <p className='turnaround-statement'>
        <Icon iconName='calendar-2'/> {translate('common.turnaroundPreceding')}<span className='turnaround-days'>{turnaroundRange}</span>{translate('common.turnaroundFollowing')}
      </p>
    );
  };

  const formClasses = classNames('b-product-form', extensionFormClass, {
    'x-disabled': dataIsFetching
  });
  const buttonGroupClasses = classNames('b-button-group', {
    'x-fixed': buttonAddToCartIsVisible
  });
  const addToCartButtonClasses = classNames('b-add-to-cart', {
    'x-add-new-item': !isEditMode,
    'x-add-to-cart-out-of-stock': isOutOfStock
  });
  const priceSectionClasses = classNames('x-price-container', {
    'x-no-vat': !taxApplied
  });
  const quantitySectionClasses = classNames('detail-section x-quantity-container', {
    'x-no-vat': !taxApplied
  });
  const imageWrapperClasses = classNames('image-wrapper', {
    'x-product-page-image-wrapper': !hasSquareImages,
    'x-square-image-page-image-wrapper': hasSquareImages
  });
  const detailsWrapperClasses = classNames('details-wrapper', {
    'x-product-page-details-wrapper': !hasSquareImages,
    'x-square-image-page-details-wrapper': hasSquareImages
  });
  const polartecFleeceAwardClasses = classNames('polartec-fleece-award', {
    'polartec-fleece-award-metric': !measurementSystemIsImperial,
    'polartec-fleece-award-fq': isFabricFqSize,
    'polartec-fleece-award-swatch': isFabricSwatchSize
  });
  const isMobileView = isTabletDown(viewport);

  return (
    <ErrorBoundary>
      <form className={formClasses} onSubmit={preventDefaultOnSubmit}>
        {productType !== FABRIC_BLANK &&
          isMobileView &&
            <ErrorBoundary>
              <DesignHeading
                tags={tags}
                locale={locale}
                userIsDesigner={userIsDesigner}
                userId={userId}
                userType={userType}
                designerUserName={designerUserName}
                designId={designId}
                designTitle={designName}
                designThumbnail={designThumbnail}
                reviewSummary={reviewSummary}
                productFormTitle={productFormTitle}
                productTypeAbbreviated={productTypeAbbreviated}
              />
              {productType !== FABRIC_BLANK && isNotUndefined(addToCartIsFetching) &&
            <section className={priceSectionClasses}>
              <ProductFormPrice
                rawItemPrice={rawItemPrice}
                rawItemPriceUndiscounted={rawItemPriceUndiscounted}
                rawItemPriceAddition={rawItemPriceAddition}
                rawItemPriceAdditionUndiscounted={rawItemPriceAdditionUndiscounted}
                discountsApplied={discountsApplied}
                additionTotalPriceMap={getAdditions()}
                selectedAddition={selectedAddition}
                pricePerUnit={pricePerUnit}
                taxApplied={taxApplied}
                currency={currency}
                measurementSystem={measurementSystem}
                homeGoodType={homeGoodType}
                promoName={promoName}
              />
            </section>}
              {
                (userIsDesigner && isHomeGood) && <PromoInfo promoName={translate('designs.designerDiscount')} />
              }

              {(showAfterpayWidget && objectIsNotEmpty(afterpayAvailability)) &&
            <AfterpayInfoBox
              afterpayAvailability={afterpayAvailability}
              country={country}
              isTextButton={true}/>}
            </ErrorBoundary>
        }
        <ErrorBoundary>
          <div className={imageWrapperClasses}>
            <ProductFormCarousel
              designImages={designImages}
              carouselImages={carouselImages}
              solidImages={solidImages}
              measurementSystem={measurementSystem}
              selectedSubstrate={selectedSubstrate}
              productSize={productSize}
              productTypeAbbreviated={productTypeAbbreviated}
              homeGoodType={homeGoodType}
              wallpaperType={wallpaperType}
              hasSquareImages={hasSquareImages}
              swiperParams={swiperParams}
              designName={designName}
            />
            {isFabric && selectedSubstrate === FABRIC_POLARTEC_FLEECE &&
            <div className={polartecFleeceAwardClasses}>
              <LazyImage
                src={`${imagesHost}/apex-awards.svg`}
                alt='apex-awards'
                height='50'
                width='50'/>
              <span className='award-description'>
                {translate('fabricShop.FABRIC_POLARTEC_FLEECE.awardDescription')}
              </span>
            </div>}
            {isFscWallpaperType &&
            <div className='fsc-certification-wrapper'>
              <LinkWrapper
                hrefValue='https://support.spoonflower.com/hc/en-us/articles/21439563748109'
                className='fsc-certification-link'
                contents={<LazyImage
                  src={`${imagesHost}/fsc-certification.svg`}
                  className='fsc-certification-image'
                  alt='fsc-certification'
                />}
              />
              <span className='fsc-certification-description'>
                {translate(`fabricShop.${selectedSubstrate}.awardDescription`)}
              </span>
            </div>}
          </div>
        </ErrorBoundary>
        <div className={detailsWrapperClasses}>
          {(productType === FABRIC_BLANK && isNotUndefined(productInfoRef)) &&
          <ErrorBoundary>
            <ProductFormFabricDetails
              locale={locale}
              selectedSubstrate={selectedSubstrate}
              fabricUnit={fabricUnit}
              handleShopClick={handleShopClick}
              handleSwatchClick={handleSwatchClick}
              productInfoRef={productInfoRef}
              swatchButtonText={renderSwatchButtonText().text}
            />
          </ErrorBoundary>}
          {productType !== FABRIC_BLANK &&
          <React.Fragment>
            {!isMobileView &&
              <ErrorBoundary>
                <DesignHeading
                  tags={tags}
                  locale={locale}
                  userIsDesigner={userIsDesigner}
                  userId={userId}
                  userType={userType}
                  designerUserName={designerUserName}
                  designId={designId}
                  designTitle={designName}
                  designThumbnail={designThumbnail}
                  reviewSummary={reviewSummary}
                  productFormTitle={productFormTitle}
                  productTypeAbbreviated={productTypeAbbreviated}
                />
                {productType !== FABRIC_BLANK && isNotUndefined(addToCartIsFetching) &&
              <section className={priceSectionClasses}>
                <ProductFormPrice
                  rawItemPrice={rawItemPrice}
                  rawItemPriceUndiscounted={rawItemPriceUndiscounted}
                  rawItemPriceAddition={rawItemPriceAddition}
                  rawItemPriceAdditionUndiscounted={rawItemPriceAdditionUndiscounted}
                  discountsApplied={discountsApplied}
                  additionTotalPriceMap={getAdditions()}
                  selectedAddition={selectedAddition}
                  pricePerUnit={pricePerUnit}
                  taxApplied={taxApplied}
                  currency={currency}
                  measurementSystem={measurementSystem}
                  homeGoodType={homeGoodType}
                  promoName={promoName}
                />
              </section>}
                {
                  (userIsDesigner && isHomeGood) && <PromoInfo promoName={translate('designs.designerDiscount')} />
                }

                {(showAfterpayWidget && objectIsNotEmpty(afterpayAvailability)) &&
              <AfterpayInfoBox
                afterpayAvailability={afterpayAvailability}
                country={country}
                isTextButton={true}/>}
              </ErrorBoundary>
            }
            {(recommendedFabricIsActive && !recommendedFabricIsPetal && typeof recommendedFabricCode === 'string' && typeof recommendedFabricWidth === 'string') &&
            <ErrorBoundary>
              <RecommendedFabric
                measurementSystem={measurementSystem}
                recommendedFabricCode={recommendedFabricCode}
                recommendedFabricWidth={recommendedFabricWidth}
              />
            </ErrorBoundary>}

            {isNotUndefined(colorways) && !isEmpty(colorways) &&
            <ErrorBoundary>
              <ProductFormColorways
                colorways={colorways}
                selectedColorway={selectedColorway}
                productType={productType}
                orderItemId={orderItemId}
                productTypeAbbreviated={productTypeAbbreviated}
                sizeQuery={productSize}
                currency={currency}
                rawItemPrice={rawItemPrice}
                productSize={productSize}
                designerUserName={designerUserName}
              />
            </ErrorBoundary>}

            {Array.isArray(fabricList) && !wallpaperType &&
            <ErrorBoundary>
              <SelectFabric
                fabricList={fabricList}
                openFabricPreferencesModal={openFabricPreferencesModal}
                selectedSubstrate={selectedSubstrate}
                outOfStock={isOutOfStock}
                designNotPurchasable={designNotPurchasable}
                productAvailable={productAvailable}/>
            </ErrorBoundary>}

            {Array.isArray(fabricList) && wallpaperType &&
            <ErrorBoundary>
              <SelectWallpaper
                optionArray={fabricList}
                openFabricPreferencesModal={openFabricPreferencesModal}
                selectedSubstrate={selectedSubstrate}
                outOfStock={isOutOfStock}
                designNotPurchasable={designNotPurchasable}
                productAvailable={productAvailable}
                setWallpaper={setWallpaper}
              />
            </ErrorBoundary>}
          </React.Fragment>}

          {(solidColorCode && solidHexCode) &&
          <SelectColor
            solidColorCode={solidColorCode}
            solidHexCode={solidHexCode}
            openSelectColorModal={openSelectColorModal}
          />}

          {productType === SOLID &&
          <ErrorBoundary>
            <ProductFormOptions
              optionType={PRODUCT_SIZE}
              productType={productType}
              measurementSystem={measurementSystem}
              solidSize={solidSize}
            />
          </ErrorBoundary>}

          {(objectIsNotEmpty(fabricSizeSizingMap) || objectIsNotEmpty(productPricingMap)) &&
            (displayHomeGoodSizes || isWallpaperOrFabric || hasNoSizeOption(productType)) && typeof productSize === 'string' && !isColorMapProduct(designId) &&
            <ErrorBoundary>
              <ProductFormOptions
                productSize={productSize}
                productType={productType}
                optionType={PRODUCT_SIZE}
                homeGoodType={homeGoodType}
                productTypeAbbreviated={productTypeAbbreviated}
                fabricSizeSizingMap={fabricSizeSizingMap}
                productPricingMap={productPricingMap}
                measurementSystem={measurementSystem}
                sizeStyleMap={sizeStyleMap}
                quantity={quantity}
                handleOnChange={handleOnChange}
                sizesAreAvailable={sizesAreAvailable}
                stylesAreAvailable={stylesAreAvailable}
                stylesArray={stylesArray}
                designNotPurchasable={designNotPurchasable}
                productAvailable={productAvailable}
                singleFabricSizeAvailable={singleFabricSizeAvailable}
                openWallpaperCalculatorModal={openWallpaperCalculatorModal}
                selectedSubstrate={selectedSubstrate}
                modalInitiatorRef={modalInitiatorRef}
              />
            </ErrorBoundary>}

          {(objectIsNotEmpty(fabricSizeSizingMap) || objectIsNotEmpty(productPricingMap)) &&
            (displayHomeGoodStyles && !pillowWithoutFlangedOption(productType)) && typeof productSize === 'string' &&
            <ErrorBoundary>
              <ProductFormOptions
                productSize={productSize}
                optionType={PRODUCT_STYLE}
                productType={productType}
                homeGoodType={homeGoodType}
                productTypeAbbreviated={productTypeAbbreviated}
                fabricSizeSizingMap={fabricSizeSizingMap}
                productPricingMap={productPricingMap}
                measurementSystem={measurementSystem}
                sizeStyleMap={sizeStyleMap}
                quantity={quantity}
                handleOnChange={handleOnChange}
                sizesAreAvailable={sizesAreAvailable}
                stylesAreAvailable={stylesAreAvailable}
                stylesArray={stylesArray}
                singleFabricSizeAvailable={singleFabricSizeAvailable}/>
            </ErrorBoundary>}

          {hasAdditionPossible &&
          <ErrorBoundary>
            <ProductFormOptions
              optionType={PRODUCT_ADDITION}
              productType={productType}
              homeGoodType={homeGoodType}
              measurementSystem={measurementSystem}
              additionTotalPriceMap={getAdditions()}
              currency={currency}
              productSize={productSize}
              setAddition={setAddition}
              selectedAddition={selectedAddition}
              additionPricing={additionPricing}
            />
          </ErrorBoundary>}

          {productType !== FABRIC_BLANK &&
          <ErrorBoundary>
            <section className={quantitySectionClasses}>
              <h3 className='h2'>{translate('fabric.headlines.quantity')}</h3>
              {isNotUndefined(addToCartIsFetching) &&
            <QuantityChanger
              isFetching={addToCartIsFetching}
              quantity={quantity}
              completingQuantityInputUpdate={completingQuantityInputUpdate}
              handleFabricQuantity={handleOnChange}
              parentComponent='productForm'
              singleFabricSizeAvailable={singleFabricSizeAvailable}/>}
            </section>
          </ErrorBoundary>}

          {(designNotPurchasable && designerUserName) &&
          <TextBox extensionClass='x-notice-text x-info'
            parentComponent='productForm'
            mainText={translate('fabric.availability.notForSale', {
              designer: <LinkWrapper
                itemProp='url'
                className='notice-url'
                hrefValue={Translate.translate('xurls.userProfile', { // TODO: SP-8362 Refactor or extend translate utility.
                  userName: designerUserName
                })?.toString() || ''}
                contents={designerUserName}
              />
            })}
          />}

          {!productAvailable &&
            <TextBox extensionClass='x-notice-text x-alert'
              parentComponent='productForm'
              mainText={translate('fabric.availability.unavailable')}
            />}

          {productType !== FABRIC_BLANK &&
          <div className={buttonGroupClasses}>
            {isEditMode &&
            <LinkWrapper
              hrefValue={MultiLocaleRouter.localePathname('cart')}
              className='btn white-button big-button'
              contents={translate('fabric.content.cancelCart')}
            />}

            {isNotUndefined(buttonContent) &&
              <AddToCartButton
                addToCartButtonClasses={addToCartButtonClasses}
                addToCartButtonTitle={addToCartButtonTitle}
                onClickFunction={handleAddToCart}
                buttonIsDisabled={buttonIsDisabled}
                buttonContent={buttonContent}
              />
            }

            {(isNotUndefined(testSwatchPrice) && !isEditMode && isWallpaper) &&
              <button
                type='submit'
                className='b-order-swatch-button'
                aria-label={translate('wallpaper.content.buyATestSwatch')}
                title={translate('wallpaper.content.buyATestSwatch')}
                disabled={buttonIsDisabled}
                onClick={handleAddSwatch}>
                {translate('wallpaper.content.buyATestSwatch')}
              </button>
            }
          </div>}

          {(isNotUndefined(testSwatchPrice) && !isEditMode && !buttonIsDisabled && isNotUndefined(homeGoodType)) &&
          <button
            type='submit'
            className='btn-swatch'
            aria-label={renderSwatchButtonText().title}
            title={renderSwatchButtonText().title}
            onClick={handleAddSwatch}>
            {renderSwatchButtonText().text}
          </button>}

          {showTurnaroundTime && renderTurnaroundTime()}
        </div>
      </form>
    </ErrorBoundary>
  );
};

export default reduxForm({
  form: 'ProductForm',
  validate: validateProductForm
})(ProductForm);
