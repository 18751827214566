import classNames from 'classnames';
import React from 'react';

import './_info-box.scss';
import ConditionalCollapsible from '../Content/ConditionalCollapsible/ConditionalCollapsible';
import AvatarImage from '../Images/AvatarImage/AvatarImage';


export interface InfoBoxProps {
  heading: string;
  imageUrl: string;
  imageAlt: string;
  infoText: JSX.Element | boolean | string;
  moreInfo: JSX.Element | boolean;
  imageClass?: string;
  boxClass: string;
  isDesktop: boolean;
  hasNoRadius?: boolean;
}

const InfoBox = ({heading, imageUrl, imageAlt, infoText, moreInfo, imageClass, boxClass, isDesktop, hasNoRadius}: InfoBoxProps): JSX.Element => {
  const boxClassName = classNames('b-info-box', boxClass);
  const wrapperClassName = classNames('info-avatar-wrapper', {
    'x-no-radius': hasNoRadius
  });

  return (
    <ConditionalCollapsible heading={heading} isDesktop={isDesktop}>
      <section className={boxClassName}>
        {isDesktop &&
        <div className='h3'>{heading}</div>}
        {imageUrl &&
        <AvatarImage
          imgSrc={imageUrl}
          wrapperExtensionClass={wrapperClassName}
          imageExtensionClass={imageClass}
        />}
        {(infoText || moreInfo) &&
        <div className='info-text-wrapper'>
          {infoText}
          {moreInfo}
        </div>}
      </section>
    </ConditionalCollapsible>
  );
};

export default React.memo(InfoBox);

