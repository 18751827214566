import Translate from 'i18n-react';
import React from 'react';

import {COUNTRY_CODE_AU, COUNTRY_CODE_US} from '../../../constants/Countries';
import {ApiHostsEnum} from '../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../entities/pageSetup/apiHosts/useApiHost';
import {translate} from '../../../services';
import {onClickPreventPropagation} from '../../../utils/events';
import {isUndefined} from '../../../utils/validation';
import Icon from '../Icon/Icon';
import LinkWrapper from '../LinkWrapper/LinkWrapper';
import './_afterpay-teaser-box.scss';


interface AfterpayTeaserBoxProps {
  closeModalButton?: JSX.Element;
  isEligibleForFAQ?: boolean;
  country?: string;
  openPreferencesModal?: () => void;
}

const AfterpayTeaserBox = ({closeModalButton, country, openPreferencesModal, isEligibleForFAQ}: AfterpayTeaserBoxProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);

  return (
    <div className='b-afterpay-teaser-box' onClick={onClickPreventPropagation}>
      {closeModalButton}
      <img className='afterpay-modal-logo' src={`${imagesHost}/afterpay_logo_black.png`} alt={translate('afterpay.logo')}/>
      <div className='afterpay-motto' id='aria-afterpay-dialog'>{translate('afterpay.modal.motto')}</div>
      {(isUndefined(isEligibleForFAQ) || isEligibleForFAQ) ?
        <React.Fragment>
          <div className='afterpay-process-container'>
            <figure className='afterpay-process'>
              <img className='afterpay-process-img' src={`${imagesHost}/afterpay-shop.svg`} alt=''/>
              <figcaption className='afterpay-process-caption'>{translate('afterpay.modal.process.stepOne')}</figcaption>
            </figure>
            <figure className='afterpay-process'>
              <img className='afterpay-process-img' src={`${imagesHost}/afterpay-laptop.svg`} alt=''/>
              <figcaption className='afterpay-process-caption'>{translate('afterpay.modal.process.stepTwo')}</figcaption>
            </figure>
            <figure className='afterpay-process'>
              <img className='afterpay-process-img' src={`${imagesHost}/afterpay-sign-up-laptop.svg`} alt=''/>
              <figcaption className='afterpay-process-caption'>{translate('afterpay.modal.process.stepThree')}</figcaption>
            </figure>
            <figure className='afterpay-process'>
              <img className='afterpay-process-img' src={`${imagesHost}/afterpay-four-payments.svg`} alt=''/>
              <figcaption className='afterpay-process-caption'>{translate('afterpay.modal.process.stepFour')}</figcaption>
            </figure>
          </div>
          <p className='afterpay-legal'>
            {country === COUNTRY_CODE_AU ?
              translate('afterpay.modal.legalAU.text', {
                'link': <LinkWrapper
                  // TODO: SP-8362 Refactor or extend translate utility functions.
                  hrefValue={Translate.translate('xurls.afterpay.terms.AU')?.toString() || ''}
                  target='_blank'
                  contents={Translate.translate('xurls.afterpay.terms.AU')?.toString() || ''}
                  className='link-text'/>
              }) :
              translate('afterpay.modal.legal.text')
            }
          </p>
          {country === COUNTRY_CODE_US && <LinkWrapper
            // TODO: SP-8362 Refactor or extend translate utility functions.
            hrefValue={Translate.translate('xurls.afterpay.installment.US')?.toString() || ''}
            target='_blank'
            contents={translate('afterpay.modal.legal.linkText')}
            className='afterpay-legal-link-text'/>
          }
        </React.Fragment> :
        <section className='notice'>
          <Icon iconName='warning1' />
          <p className='notice-text'>{translate('afterpay.landing.notice')}</p>
          <button className='btn notice-btn white-button x-dark big-button' onClick={openPreferencesModal}>{translate('afterpay.landing.preferenceButton')}</button>
          <LinkWrapper
            className='link-text'
            target='_blank'
            hrefValue={Translate.translate('xurls.afterpay.home')?.toString() || ''} // TODO: SP-8362 Refactor or extend translate utility.
            contents={translate('afterpay.landing.afterpayLink')} />
        </section>}
    </div>
  );
};

export default AfterpayTeaserBox;
