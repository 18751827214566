import classNames from 'classnames';
import React from 'react';

import './_stacked_images.scss';
import {ApiHostsEnum} from '../../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../../entities/pageSetup/apiHosts/useApiHost';
import ImageUrls from '../../../../utils/design';
import LazyImage from '../../LazyImage/LazyImage';


export interface StackedImagesProps {
  size: string;
  firstDesignId: number;
  secondDesignId?: number;
  firstDesignName: string;
  secondDesignName?: string;
  firstDesignFileName: string;
  secondDesignFileName?: string;
  firstDesignEnciphered: boolean;
  secondDesignEnciphered?: boolean;
  thumbnailExtensionClass: string;
  stackedImagesExtensionClass: string;
  a11yDecorative?: boolean;
}

const StackedImages = ({
  size, firstDesignId, secondDesignId, firstDesignName, secondDesignName, firstDesignFileName, secondDesignFileName, firstDesignEnciphered,
  secondDesignEnciphered, thumbnailExtensionClass, stackedImagesExtensionClass, a11yDecorative
}: StackedImagesProps): JSX.Element => {
  const s3host = useApiHost(ApiHostsEnum.s3);

  const designThumbnailImage = (designId: number, designName: string, designFilename: string, enciphered = false, a11yDecorative = false) => (
    <LazyImage
      height={size}
      width={size}
      alt={a11yDecorative ? '' : designName}
      className='thumbnail-image'
      src={enciphered ? designFilename : ImageUrls.designImageUrl(s3host, designId, designFilename).thumbnail}/>
  );

  const stackedImagesClasses = classNames('b-stacked-images', stackedImagesExtensionClass);
  const designThumbnailClasses = classNames('design-thumbnail', thumbnailExtensionClass, {
    'x-shadow': secondDesignId
  });
  const stackedThumbnailClasses = classNames('stacked-thumbnail', thumbnailExtensionClass);

  return (
    <section className={stackedImagesClasses}>
      <figure className={designThumbnailClasses}>
        {designThumbnailImage(firstDesignId, firstDesignName, firstDesignFileName, firstDesignEnciphered, a11yDecorative)}
      </figure>
      {secondDesignId && secondDesignName && secondDesignFileName &&
      <figure className={stackedThumbnailClasses}>
        {designThumbnailImage(secondDesignId, secondDesignName, secondDesignFileName, secondDesignEnciphered, a11yDecorative)}
      </figure>
      }
    </section>
  );
};

export default StackedImages;
