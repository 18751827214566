import classNames from 'classnames';
import React from 'react';

import {translate} from '../../../services';
import Icon from '../../Reusable/Icon/Icon';
import './_favorite-design.scss';


export interface FavoriteDesignProps {
  isFavorite: boolean;
  favoriteCount: number;
  favoriteDesign: () => void;
  parentName?: string;
  isFavoriteFetching: boolean;
  children?: JSX.Element;
}

const FavoriteDesign = ({isFavorite, favoriteCount, favoriteDesign, parentName, isFavoriteFetching, children: customIcon, ...rest}: FavoriteDesignProps): JSX.Element => {
  const renderFavoriteCount = () => (<span className='favorite-amount'>{favoriteCount}</span>);
  const isShopPage = parentName === 'shop';
  const isProductPage = parentName === 'productForm';
  const favoriteDesignClasses = classNames({
    'b-favorite-design': !customIcon,
    'x-fabric-page-favorite-design': isProductPage,
    'x-shop-page-favorite-design': isShopPage,
    'x-custom-favorite-design': customIcon,
  });
  const favoriteButtonClasses = classNames('btn', 'favorite-btn', {
    'favorited': isFavorite,
    'unfavorited': !isFavorite,
  });
  const favoriteButtonTitle = isFavorite ? translate('designs.favoriteDesignButton.deleting') : translate('designs.favoriteDesignButton.adding');
  const favoriteCountAboveZero = favoriteCount > 0;


  return (
    <div className={favoriteDesignClasses}>
      <button
        disabled={!!isFavoriteFetching}
        type='button'
        className={favoriteButtonClasses}
        aria-pressed={isFavorite}
        onClick={favoriteDesign}
        id='favorite-btn'
        aria-label={favoriteButtonTitle}
        {...rest}
      >
        {customIcon || <Icon iconName='heart' iconTitle={favoriteButtonTitle} />}
      </button>
      {favoriteCountAboveZero && isProductPage && renderFavoriteCount()}
      {favoriteCountAboveZero && isShopPage && renderFavoriteCount()}
    </div>
  );
};

export default FavoriteDesign;
