import classNames from 'classnames';
import React from 'react';

import './_text-box.scss';
import Icon from '../../Icon/Icon';
import LinkWrapper from '../../LinkWrapper/LinkWrapper';


interface TextBoxProps {
  headline?: string;
  mainText?: string;
  complementaryElement?: JSX.Element;
  extensionClass?: string;
  isNotInlineBlock?: boolean;
  parentComponent?: string;
  checklistItems?: [{
    text: string;
  }];
  inlineLinkedText?: {
    href: string;
    text: string;
  };
}

const TextBox = (props: TextBoxProps): JSX.Element => {
  const {checklistItems, mainText, headline, extensionClass, complementaryElement, inlineLinkedText, parentComponent, isNotInlineBlock} = props;
  const textBoxClasses = classNames('b-text-box', extensionClass);
  const isFromProductForm = parentComponent === 'productForm';

  return (
    <section className={textBoxClasses}>
      {headline && <div className='h4'>{headline}</div>}
      {(mainText || complementaryElement) &&
        <p className='wrapper'>
          {mainText && inlineLinkedText &&
            <React.Fragment>
              {mainText}
              <LinkWrapper
                className='link-text'
                hrefValue={inlineLinkedText.href}
                contents={inlineLinkedText.text}/>
            </React.Fragment>}
          {mainText && !inlineLinkedText && !isNotInlineBlock && <span className='main-text'>{mainText}</span>}
          {mainText && isNotInlineBlock && <span>{mainText}</span>}
          {complementaryElement}
          {isFromProductForm &&
          <span itemProp='offers' itemScope itemType='http://schema.org/Offer'>
            <link itemProp='availability' href='http://schema.org/OutOfStock'/>
          </span>
          }
        </p>}
      {checklistItems &&
        <ul className='check-list'>
          {checklistItems.map((item, index) => (
            <li key={index}><Icon iconName='checkmark'/>{item.text}</li>
          ))}
        </ul>
      }
    </section>
  );
};

export default TextBox;
