import shuffle from 'lodash/shuffle';
import React, {useMemo} from 'react';

import SwiperWrapperContainer from '../../../containers/Design/SwiperWrapperContainer';
import {translate} from '../../../services';
import {DesignResponse} from '../../../shapes/design';
import {ViewportType} from '../../../shapes/viewport';

import AllProductsItem from './AllProductsItem';


export interface AllProductDesign {
  designTitle: string;
  productType: string;
  productLink: string;
  imageSrc: string;
  itemCategories: {
    item_category: string;
    item_category2?: string;
    item_category3?: string;
  };
}

export interface AllProductsListProps {
  allProductsList: AllProductDesign[];
  viewport: ViewportType;
  userId?: number;
  allProductsDesign: DesignResponse;
  pushProductClickToGTM: (designId: number, designerName: string, index: number, target_path: string, designTitle: string, userId?: number) => void;
}

const AllProductsList = ({viewport, allProductsList, userId, pushProductClickToGTM, allProductsDesign}: AllProductsListProps): JSX.Element => {
  const swiperParams = {
    slidesPerView: 'auto',
    breakpoints: {
      430: {
        slidesPerGroup: 2
      },
      690: {
        slidesPerGroup: 3
      }
    }
  };


  let swiperItems: JSX.Element[] = [];

  const shuffledAllProductsList = useMemo(() => shuffle(allProductsList), [allProductsDesign.id]);

  if (shuffledAllProductsList.length > 0) {
    swiperItems = shuffledAllProductsList.map((design: AllProductDesign, index: number) => (
      <div key={index}>
        <AllProductsItem
          imageUrl={design.imageSrc}
          designTitle={design.designTitle}
          productLink={design.productLink}
          userId={userId}
          index={index}
          designId={allProductsDesign.id}
          designerName={allProductsDesign.designer.screen_name}
          pushProductClickToGTM={pushProductClickToGTM}
        />
      </div>
    ));
  }

  return (
    <section className={'b-all-products-list'}>
      <h2 className='h3'>{translate('designs.alsoAvailableAs')}</h2>
      <SwiperWrapperContainer
        viewport={viewport}
        swiperParams={swiperParams}
        navigationClasses='x-on-top-of-slides'
      >
        {swiperItems}
      </SwiperWrapperContainer>
    </section>
  );
};

export default AllProductsList;
