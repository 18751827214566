import Translate from 'i18n-react';
import React, {LegacyRef, useCallback, useRef, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import FavoriteDesign from '../../components/Product/FavoriteDesign/FavoriteDesign';
import LinkWrapper from '../../components/Reusable/LinkWrapper/LinkWrapper';
import {PopoverContainer} from '../../components/Reusable/Popover/PopoverContainer';
import {NEGATIVE_FAVORITE_COUNT, POSITIVE_FAVORITE_COUNT} from '../../constants/Design';
import {translate} from '../../services';
import {isRegisteredUser, RegisteredUser, UserState} from '../../shapes/user';
import {State} from '../../store/initialState';
import {fetchFavoriteState} from '../../utils/fetch';
import {getFavoriteCount} from '../../utils/stateToPropsHelpers';
import {isUndefined} from '../../utils/validation';


export interface FavoriteDesignContainerProps {
  designId: number;
  designerName: string;
  index: number;
pushActionOnFavoriteButtonToGTM: (
    designId: number,
    designerName: string,
    index: number,
    addToFavorites: boolean
  ) => void;
  parentName?: string;
  redirectToLogin?: boolean;
  children?: JSX.Element;
}

const FavoriteDesignContainer = ({
  designId,
  designerName,
  index,
  pushActionOnFavoriteButtonToGTM,
  parentName,
  redirectToLogin,
  children,
}: FavoriteDesignContainerProps): JSX.Element => {
  const selectFavoriteState = useCallback(
    (state: State) => {
      const resgisteredUser = isRegisteredUser(state.user as UserState) && state.user as RegisteredUser;
      const favoriteDesigns = state.userFavoriteDesigns.favoriteDesigns;
      const favoriteDesignIds = resgisteredUser && favoriteDesigns ? Object.keys(favoriteDesigns).filter((k: string) => favoriteDesigns[Number(k)]) : [];
      const isFavoriteFetching = state.design.isFetchingFavoriteDesign || state.userFavoriteDesigns.isFetching;

      return {
        isFavorite: !isUndefined(favoriteDesignIds) && favoriteDesignIds.includes(designId.toString()),
        favoriteCount: getFavoriteCount(state, designId),
        isFavoriteFetching
      };
    },
    [designId]
  );
  const {isFavorite, favoriteCount, isFavoriteFetching} = useSelector(selectFavoriteState, shallowEqual);
  const dispatch = useDispatch();
  const dispatchFavoriteState = (
    designId: number,
    addedFavoriteCount: number,
    pushActionOnFavoriteButtonToGTM: (
      designId: number,
      designerName: string,
      index: number,
      addToFavorites: boolean
    ) => void) => {
    fetchFavoriteState(dispatch, designId, addedFavoriteCount);
    pushActionOnFavoriteButtonToGTM(designId, designerName, index, isFavorite);
  };
  const [displayLoginPopover, setDisplayLoginPopover] = useState(false);
  const ref = useRef<HTMLElement>(null);
  const popoverPositionComponent = ref.current?.querySelector('#favorite-btn') as HTMLElement;
  const customIcon = children && React.cloneElement(children, {
    isFavorite
  });

  function favoriteDesign() {
    const addedFavoriteCount = isFavorite === false ? POSITIVE_FAVORITE_COUNT : NEGATIVE_FAVORITE_COUNT;

    dispatchFavoriteState(designId, addedFavoriteCount, pushActionOnFavoriteButtonToGTM);
  }

  const PopoverContent = (
    <div>
      <LinkWrapper className='auth-link' hrefValue={Translate.translate('xurls.login')?.toString() || ''} contents={translate('common.login')} />
      {translate('common.or')}
      <LinkWrapper className='auth-link' hrefValue={Translate.translate('xurls.header.join')?.toString() || ''} contents={translate('common.createAccount')} />
      {translate('designs.addFavorite')}
    </div>
  );

  const onFavoriteDesignClick = () => {
    if (redirectToLogin) {
      setDisplayLoginPopover((prevState) => !prevState);
    } else {
      favoriteDesign();
    }
  };

  return (
    <div ref={ref as LegacyRef<HTMLDivElement>}>
      <PopoverContainer
        content={PopoverContent}
        open={displayLoginPopover}
        onClose={() => setDisplayLoginPopover((prevState) => !prevState)}
        anchorElRef={popoverPositionComponent}
        role='alertdialog'
        aria-live='polite'
      />
      <FavoriteDesign
        isFavorite={isFavorite}
        favoriteCount={favoriteCount}
        favoriteDesign={onFavoriteDesignClick}
        parentName={parentName}
        isFavoriteFetching={isFavoriteFetching}
      >
        {customIcon}
      </FavoriteDesign>
    </div>
  );
};

export default FavoriteDesignContainer;
