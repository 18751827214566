import classNames from 'classnames';
import React, {useEffect, useState, MouseEvent} from 'react';

import './_collapsible.scss';
import {isFunction, objectIsNotEmpty} from '../../../../utils/validation';
import {ErrorBoundary} from '../../ErrorBoundary/ErrorBoundary';
import Icon from '../../Icon/Icon';


export interface CollapsibleProps {
  title?: string;
  secondaryTitle?: string;
  classname?: string;
  sizeClass: string;
  subtitleClass?: string;
  subtitleTextClass?: string;
  content: JSX.Element;
  collapsibleOpen: boolean;
  triggerClosed?: boolean;
  handleCollapsibleClick?: (localCollapsibleOpen: boolean) => void;
  triggerType: 'plus' | 'chevron' | 'add-circle';
  isDesignerInfo?: boolean;
  isFabricInfo?: boolean;
  isPromoCode?: boolean;
  isCollectionDetails?: boolean;
  collapsibleShowTrigger?: boolean;
  prefixContent?: JSX.Element;
}

const Collapsible = ({
  title, secondaryTitle, classname, content, sizeClass, triggerType, isDesignerInfo, isFabricInfo,
  subtitleClass, subtitleTextClass, collapsibleShowTrigger, isPromoCode, collapsibleOpen, triggerClosed,
  handleCollapsibleClick, prefixContent, isCollectionDetails
}: CollapsibleProps): JSX.Element => {
  const [localCollapsibleOpen, setLocalCollapsibleOpen] = useState(collapsibleOpen);

  useEffect(() => {
    setLocalCollapsibleOpen(collapsibleOpen);
    triggerClose(localCollapsibleOpen, triggerClosed);
  }, [collapsibleOpen]); // eslint-disable-line

  const toggleDetails = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    setLocalCollapsibleOpen(!localCollapsibleOpen);

    if (isFunction(handleCollapsibleClick)) {
      handleCollapsibleClick(!localCollapsibleOpen);
    }
  };

  const triggerClose = (collapsibleOpen: boolean, nextPropTriggerClosed?: boolean) => {
    if (nextPropTriggerClosed && collapsibleOpen) {
      setLocalCollapsibleOpen(false);
    }
  };

  const collapsibleClasses = classNames('b-collapsible', classname, {
    'x-active': localCollapsibleOpen && !isDesignerInfo,
    'x-active-designer-info': localCollapsibleOpen && isDesignerInfo
  });
  const triggerClasses = classNames('trigger', sizeClass);
  const subtitleClasses = classNames('subtitle', subtitleClass, {
    'subtitle-with-space': !isDesignerInfo && !isFabricInfo && !isPromoCode && !isCollectionDetails,
    'subtitle-without-space': isFabricInfo
  });
  const subtitleTextClasses = classNames('text', subtitleTextClass);
  const collapsibleTitle = secondaryTitle ? (localCollapsibleOpen ? secondaryTitle : title) : title;

  return (
    <ErrorBoundary>
      <section className={collapsibleClasses}>
        {objectIsNotEmpty(prefixContent) && prefixContent}
        <div className={subtitleClasses}>
          {isDesignerInfo &&
          <span className='content-above-trigger' aria-hidden={!localCollapsibleOpen}>
            {content}
          </span>}
          {collapsibleShowTrigger &&
          <button
            className={triggerClasses}
            aria-label={title}
            type='button'
            onClick={toggleDetails}
            aria-expanded={localCollapsibleOpen}>
            {triggerType === 'plus' &&
            <React.Fragment>
              <Icon iconName='plus'/>
              <Icon iconName='minus'/>
            </React.Fragment>}
            {triggerType === 'add-circle' &&
            <React.Fragment>
              <Icon iconName='add-circle'/>
              <Icon iconName='remove-circle'/>
            </React.Fragment>}
            <span className={subtitleTextClasses}>{collapsibleTitle}</span>
            {triggerType === 'chevron' &&
            <React.Fragment>
              <Icon iconName='chevron-down'/>
              <Icon iconName='chevron-up'/>
            </React.Fragment>}
          </button>}
        </div>
        <div className='content' aria-hidden={!localCollapsibleOpen}>
          {content}
        </div>
      </section>
    </ErrorBoundary>
  );
};

Collapsible.defaultProps = {
  collapsibleShowTrigger: true
};

export default Collapsible;
