import classNames from 'classnames';
import React from 'react';

import './_avatar-image.scss';
import {AVATAR_SIZE} from '../../../../constants/Design';
import LazyImage from '../../LazyImage/LazyImage';


export interface AvatarImageProps {
  imgSrc: string;
  wrapperExtensionClass?: string;
  imageExtensionClass?: string;
}

const AvatarImage = ({imgSrc, wrapperExtensionClass, imageExtensionClass}: AvatarImageProps): JSX.Element => {
  const wrapperClass = classNames('b-avatar-image', wrapperExtensionClass);
  const imageClass = classNames('avatar-image', imageExtensionClass);

  return (
    <div className={wrapperClass}>
      <LazyImage
        height={AVATAR_SIZE}
        width={AVATAR_SIZE}
        alt={''} // LevelAccess https://spoonflower.atlassian.net/browse/SP-24830
        className={imageClass}
        src={imgSrc}
      />
    </div>
  );
};

export default AvatarImage;
