/* eslint-disable complexity */
import classNames from 'classnames';
import React, {SyntheticEvent} from 'react';
import {useSelector} from 'react-redux';
import './_substrate-item.scss';

import {
  NEW_FABRICS, WALLPAPER
} from '../../../constants/Codes';
import {MeasurementSystem, measurementSystemToUnit} from '../../../constants/Measurements';
import {FABRIC_IMAGE_HEIGHT, FABRIC_IMAGE_WIDTH} from '../../../constants/Products';
import {getDefaultCountry, getDefaultMeasurementSystem} from '../../../constants/UserPreferences';
import SwiperWrapperContainer from '../../../containers/Design/SwiperWrapperContainer';
import {ApiHostsEnum} from '../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../entities/pageSetup/apiHosts/useApiHost';
import {selectUserDefaults} from '../../../entities/user/selectors';
import {translate} from '../../../services';
import {ViewportType} from '../../../shapes/viewport';
import {setPlaceholderImage} from '../../../utils/design';
import {getMeasurement, formattedMeasurementIsNaN} from '../../../utils/fabrics';
import {isEmpty, isNotUndefined} from '../../../utils/validation';
import Collapsible from '../Content/Collapsible/Collapsible';
import LazyImage from '../LazyImage/LazyImage';
import LinkWrapper from '../LinkWrapper/LinkWrapper';


export interface SubstrateItemProps {
  fabricCode?: string;
  viewport: ViewportType;
  name: string | (string | JSX.Element)[];
  subtitle?: string;
  altTextFabricName?: string;
  isSelected?: boolean;
  pricePerUnit?: string;
  description: string | JSX.Element | (string | JSX.Element)[];
  details?: string | JSX.Element;
  setSubstrateClick?: (substrateString: string) => void;
  buttonText: string;
  selectButtonText?: string;
  selectedButtonText?: string;
  collapsibleText?: string;
  collapsibleTextSecond?: string;
  collapsibleOpen?: boolean;
  displayFabricsModal?: boolean;
  showPrice?: boolean;
  hideSwiper?: boolean;
  imageSource?: string;
  imageHeight?: string;
  imageWidth?: string;
  buttonClass?: string;
  outOfStock?: boolean;
  descriptionLink?: string;
  descriptionLinkTitle?: string;
  descriptionLinkTwo?: string;
  descriptionLinkTwoTitle?: string;
  measurementSystem?: MeasurementSystem;
  locale?: string;
  hideButton?: boolean;
  wrappedLinkText?: string;
  learnMoreLink?: string;
}

const SubstrateItem = ({
  fabricCode, name, pricePerUnit, description, details, collapsibleText, collapsibleTextSecond, setSubstrateClick, buttonText,
  selectButtonText, selectedButtonText, isSelected, collapsibleOpen, displayFabricsModal, altTextFabricName, showPrice, hideSwiper,
  imageSource, imageWidth, imageHeight, buttonClass, descriptionLink, outOfStock, descriptionLinkTitle, descriptionLinkTwo,
  descriptionLinkTwoTitle, wrappedLinkText, viewport, measurementSystem, locale, hideButton, learnMoreLink, subtitle
}: SubstrateItemProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);
  const userDefaults = useSelector(selectUserDefaults);
  const removeCarouselImages = (event: SyntheticEvent) => {
    const targetNode = event.target as HTMLElement;

    if (targetNode && targetNode.parentNode) {
      const swiper = targetNode.parentNode.parentNode;

      while (swiper && swiper.lastChild && swiper.firstChild !== swiper.lastChild) {
        swiper.removeChild(swiper.lastChild);
      }
    }
  };

  const handleImageError = (event: SyntheticEvent<HTMLDivElement>) => {
    setPlaceholderImage(event, imagesHost);
    removeCarouselImages(event);
  };

  const isWallpaper = fabricCode?.includes(WALLPAPER);
  const displayImageSource = imageSource ? imageSource : `${imagesHost}/fabrics/${fabricCode}.jpg`;
  const dangerousHtml = {
    __html: `<ul class='circle-list'>${details}</ul>`
  };
  const itemClasses = classNames('b-substrate-item', 'substrate-image-wrapper', {
    'x-fabrics-modal': displayFabricsModal
  }, {
    'x-out-of-stock-substrate-item': outOfStock
  });
  const buttonClasses = isSelected ? classNames('btn', 'black-button') : classNames('btn', buttonClass);
  const learnMoreClasses = displayFabricsModal ? classNames('learn-more') : classNames('btn white-button');
  const swiperParams = {
    spaceBetween: 1, // fixes overlapping of slides
    // ...(!isWallpaper && { // TODO: SP-10809 Circular carousel for fabric-shop
      // loop: true
    // })
  };

  const unit = measurementSystemToUnit[measurementSystem || getDefaultMeasurementSystem(getDefaultCountry(userDefaults))];
  const measurement = isNotUndefined(locale) ? {
    width: getMeasurement(fabricCode, unit, 'printableWidth', locale),
    weight: getMeasurement(fabricCode, unit, 'weight', locale),
  } : undefined;
  const size = isNotUndefined(measurement) ? translate(`fabricShop.SIZE.${unit}`, {
    width: measurement.width,
    weight: measurement.weight
  }) : '';
  const widthAndWeightAreAvailable = isNotUndefined(measurement) && !formattedMeasurementIsNaN(measurement.width) && !formattedMeasurementIsNaN(measurement.weight);
  const handleClickSetSubstrate = () => {
    if (setSubstrateClick && fabricCode && !outOfStock) {
      setSubstrateClick(fabricCode);
    }
  };

  const fabricCarouselImageSources = [
    `${imagesHost}/fabric_carousel_images/${fabricCode}_2.jpg`,
    `${imagesHost}/fabric_carousel_images/${fabricCode}_3.jpg`,
    `${imagesHost}/fabric_carousel_images/${fabricCode}_4.jpg`
  ];

  const substrateImages = [
    <LazyImage
      key={0}
      className='substrate-image'
      height={imageHeight || FABRIC_IMAGE_HEIGHT}
      width={imageWidth || FABRIC_IMAGE_WIDTH}
      src={displayImageSource}
      alt={altTextFabricName}
      onError={handleImageError}
      itemProp='image' />
  ];

  substrateImages.length < 2 && !isWallpaper && fabricCarouselImageSources.map((imageSource, index) => { // eslint-disable-line
    // when all images are lazy loaded, we don't get an error so the swiper renders all slides with broken images
    // assume that if the first fabricCarouselImage is there, the rest is as well
    substrateImages.push(<img
      key={index + 1}
      className='substrate-image swiper-lazy'
      height={imageHeight || FABRIC_IMAGE_HEIGHT}
      width={imageWidth || FABRIC_IMAGE_WIDTH}
      data-src={imageSource}
      alt={altTextFabricName}
      onError={removeCarouselImages}
      itemProp='image' />
    );
  });

  const renderSingleImage = () => {
    const image = <LazyImage
      className='substrate-image'
      height={imageHeight || FABRIC_IMAGE_HEIGHT}
      width={imageWidth || FABRIC_IMAGE_WIDTH}
      src={displayImageSource}
      alt={altTextFabricName}
      onError={handleImageError}
      onClick={handleClickSetSubstrate}
      itemProp='image' />;

    return descriptionLink ?
      <LinkWrapper
        className={`substrate-image-wrapper ${outOfStock && 'out-of-stock-image-wrapper'}`}
        hrefValue={descriptionLink}
        contents={<React.Fragment>
          {outOfStock &&
            <div className='out-of-stock-message-wrapper'>
              <div className='out-of-stock-message-text'>
                {translate('fabric.outOfStock.messageGeneric')}
              </div>
            </div>
          }
          <span className={`${outOfStock && 'out-of-stock-image'}`}>
            {image}
          </span>
        </React.Fragment>}
      /> :
      <div className={`substrate-image-wrapper ${outOfStock && 'out-of-stock-image-wrapper'}`}>
        {outOfStock &&
          <div className='out-of-stock-message-wrapper'>
            <div className='out-of-stock-message-text'>
              {translate('fabric.outOfStock.messageGeneric')}
            </div>
          </div>
        }
        <span className={`${outOfStock && 'out-of-stock-image'}`}>
          {image}
        </span>
      </div>;
  };

  const isNewFabric = NEW_FABRICS.includes(fabricCode ?? '');

  return (
    <li
      aria-label={`${buttonText} - ${altTextFabricName}`}
      title={`${buttonText} - ${altTextFabricName}`}
      className={itemClasses}
      itemScope
      itemType='http://schema.org/Product'>
      {!displayFabricsModal && !hideSwiper ?
        <SwiperWrapperContainer
          viewport={viewport}
          parentComponent='substrateItem'
          swiperParams={swiperParams}
          onClickFunction={handleClickSetSubstrate}>
          {substrateImages}
        </SwiperWrapperContainer> : renderSingleImage()}
      <div className='substrate-description'>
        {isNewFabric && <span className='substrate-label'>{translate('fabric.aboutFabric.newLabel')}</span>}
        <div className='h3 item-name' itemProp='name'>{name}</div>
        {(!isEmpty(size) && widthAndWeightAreAvailable) && <p className='fabric-size' itemProp='width weight'>{size}</p>}
        {subtitle && <p className='substrate-subtitle' itemProp='width weight'>{subtitle}</p>}
        {description && <div className='description' itemProp='description'>{description}</div>}
        {descriptionLink && descriptionLinkTitle &&
          <LinkWrapper className='description-link' hrefValue={descriptionLink} title={descriptionLinkTitle} contents={descriptionLinkTitle} />}
        {wrappedLinkText &&
          <span className='description-link'>{wrappedLinkText}</span>}
        {descriptionLinkTwo && descriptionLinkTwoTitle &&
          <LinkWrapper className='description-link' hrefValue={descriptionLinkTwo} title={descriptionLinkTwoTitle} contents={descriptionLinkTwoTitle} />}
        {details &&
          <Collapsible
            title={collapsibleText}
            secondaryTitle={collapsibleTextSecond}
            sizeClass='x-trigger-small'
            collapsibleOpen={collapsibleOpen || false}
            classname={'more-details'}
            triggerType='plus'
            content={isWallpaper ? details as JSX.Element : <div dangerouslySetInnerHTML={dangerousHtml} />}
            isFabricInfo={true} />}
        <div className='price-select-wrapper'>
          {showPrice &&
            <div className='h4 price-per-unit'>{pricePerUnit}</div>}
          {!hideButton && selectButtonText && displayFabricsModal &&
            <button
              type='button'
              className={buttonClasses}
              onClick={handleClickSetSubstrate}
              aria-label={selectButtonText}
              disabled={isSelected || outOfStock}>
              {isSelected ? selectedButtonText : selectButtonText}
            </button>}
          {!hideButton && learnMoreLink &&
            <LinkWrapper
              hrefValue={learnMoreLink}
              className={learnMoreClasses}
              title={`${translate('fabric.aboutFabric.learnMore')} about ${altTextFabricName}`}
              contents={translate('fabric.aboutFabric.learnMore')}
            />}
        </div>
      </div>
    </li>
  );
};

export default SubstrateItem;
