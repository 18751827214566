import React, {SyntheticEvent} from 'react';

import {ApiHostsEnum} from '../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../entities/pageSetup/apiHosts/useApiHost';
import {translate} from '../../../services';
import {setPlaceholderImage} from '../../../utils/design';
import LazyImage from '../../Reusable/LazyImage/LazyImage';
import LinkWrapper from '../../Reusable/LinkWrapper/LinkWrapper';
import './_all-products-item.scss';


export interface AllProductsItemProps {
  imageUrl: string;
  designTitle: string;
  productLink: string;
  designId: number;
  designerName: string;
  index: number;
  userId?: number;
  pushProductClickToGTM?: (designId: number, designerName: string, index: number, target_path: string, designTitle: string, userId?: number) => void;
}

const AllProductsItem = ({imageUrl, productLink, designTitle, designId, designerName, index, userId, pushProductClickToGTM}: AllProductsItemProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);
  const titleText = translate('tags.main.chooseDesign');


  const onErrorFunc = (e: SyntheticEvent<HTMLImageElement, Event>) => setPlaceholderImage(e, imagesHost);
  const pushProductOnClick = () => pushProductClickToGTM && pushProductClickToGTM(designId, designerName, index, productLink, designTitle, userId);

  const linkContent = <div>
    <LazyImage
      aspectRatio={true}
      className='item-pic'
      src={imageUrl}
      alt={designTitle}
      itemProp='image'
      onError={onErrorFunc}
      height='294'
      width='294'
      data-testid='design-item-image'/>
    <p className='item-title-wrapper'>
      {designTitle}
    </p>
  </div>;

  return (
    <div
      className='b-design-item x-condensed'
      itemProp='isRelatedTo'
      itemScope itemType='https://schema.org/Product'
      onClick={pushProductOnClick}
    >
      <LinkWrapper hrefValue={productLink} contents={linkContent} title={`${titleText} for ${designTitle}`} className='design-link' />
    </div>
  );
};

export default AllProductsItem;
