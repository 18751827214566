import get from 'lodash/get';
import React from 'react';

import {WALLPAPER_GOLD_METALLIC, WALLPAPER_GRASSCLOTH, WALLPAPER_ISOBAR, WALLPAPER_SILVER_METALLIC, WALLPAPER_SMOOTH, WALLPAPER_VINYL} from '../constants/Codes';
import {translate} from '../services/index';

import {isNotUndefined} from './validation';


export const wallpaperDetailSections = (wallpaper: string, measurementSystem: string, repeat?: number): Record<string, string> => {
  const createLink = <a className='list-link' href={translate(`fabricShop.${wallpaper}.urlInstructionsLink`)}>{translate(`fabricShop.${wallpaper}.urlInstructions`)}</a>;

  if (isNotUndefined(repeat)) {
    return {
      'dimensions': translate(`fabricShop.${wallpaper}.designDimensions.${measurementSystem}`, {
        repeatSize: repeat
      }),
      'installation': translate(`fabricShop.${wallpaper}.installation`, {
        urlLink: createLink
      }),
      'details': translate(`fabricShop.${wallpaper}.details`),
      'note': translate(`fabricShop.${wallpaper}.note`)
    };
  }

  return {
    'dimensions': translate(`fabricShop.${wallpaper}.dimensions.${measurementSystem}`),
    'installation': translate(`fabricShop.${wallpaper}.installation`, {
      urlLink: createLink
    }),
    'details': translate(`fabricShop.${wallpaper}.details`),
    'note': translate(`fabricShop.${wallpaper}.note`)
  };
};

export const isUnitRoll = (selectedSubstrateCode: string): boolean =>
  [
    WALLPAPER_ISOBAR,
    WALLPAPER_GRASSCLOTH,
    WALLPAPER_GOLD_METALLIC,
    WALLPAPER_SILVER_METALLIC,
    WALLPAPER_VINYL,
  ].includes(selectedSubstrateCode);

export const getWallpaperThumbnail = (wallpaperImages: Record<string, Record<string, Record<string, string>>>, selectedSubstrateCode: string = WALLPAPER_SMOOTH): string => {
  const defaultImageCode = Object.keys(wallpaperImages[selectedSubstrateCode])[0];

  return get(wallpaperImages[selectedSubstrateCode][defaultImageCode], 'thumbnail','');
};

export const isOutOfStock = (active: boolean, outOfStock: boolean, standby: boolean): boolean => !active && outOfStock && standby;
